export default ({ app }, inject) => {
    inject('getOrderedList', getOrderedList);
    inject('getCreditCheckString', getCreditCheckString);
    inject('getDirectPaymentToBanksString', getDirectPaymentToBanksString);
    inject('removeParagraphs', removeParagraphs);
    inject('formatId', formatId);
    inject('getAlternateDataKeys', getAlternateDataKeys);
    inject('getAlternateDataConfig', getAlternateDataConfig);
    inject('getSpanishLoanDuration', getSpanishLoanDuration);
    inject('getSpanishDebtCollectionDuration', getSpanishDebtCollectionDuration);

    inject('isNorway', isNorway);
    inject('isSweden', isSweden);
    inject('isSpain', isSpain);
    inject('isEstonia', isEstonia);
    inject('isDenmark', isDenmark);


    inject('formatBoolean', (boolean) => {
        return boolean ? app.$translate('yes', 'Ja') : app.$translate('no', 'Nej');
    });

    inject('getCurrencySymbol', () => {
        // defaults to SEK if no currency, returning 'kr' as symbol
        const currency = process.env.CURRENCY || 'SEK';
        return {
            'SEK': 'kr',
            'NOK': 'kr',
            'EUR': '€',
            'DKK': 'kr'
        }[currency] || '';
    });


    inject('validateText', (textElement) => {
        if (typeof(textElement) === 'string') {
            return Boolean(textElement?.trim());
        }
        return Boolean(app.$prismic.asText(textElement)?.trim());
    });

    inject('getDocumentPreamble', (document) => {
        let preamble = document.data.preamble;
        if (app.$validateText(preamble)) {
            return preamble;
        }
        document.data.body
            .some(slice => slice.slice_type === 'content' && slice.primary.content
                .some(content => {
                    if (content.type === 'paragraph') {
                        preamble = content.text;
                        return true;
                    }
                    return false;
                })
            );
        return preamble;
    });

    inject('getLoanAmountTooltip', (model, alternateDataType, alternateDataDisplay) => {
        const existingCustomersTooltip = model.hasExtraLoanAmountToExistingCustomers 
            ? model.getMaxAmountToExistingCustomersString(app.$translate('max_amount_to_existing_customers', 'Upp till {amount} för existerande kunder'))
            : null;
        
        const debtCollectionTooltip = alternateDataType === 'debt_collection' && alternateDataDisplay === 'tooltip' && model.hasDebtCollectionAmount
            ? app.$translate('debt_collection_amount_tooltip', 'Om du väljer att samla dina lån är beloppet istället {amount}')
                .replace('{amount}', model.amountDebtCollectionString)
            : null;
        
        return [existingCustomersTooltip, debtCollectionTooltip]
            .filter(Boolean)
            .join('<br>') || null;
    });

    inject('getLoanDurationTooltip', (model, alternateDataType, alternateDataDisplay) => {
        if (model.hasDebtCollectionDuration && alternateDataType === 'debt_collection' && alternateDataDisplay === 'tooltip') {
            const duration = app.$isSpain()
                ? app.$getSpanishDebtCollectionDuration(model, app.$translate('month_short'), app.$translate('year_short'))
                : model.getDurationDebtCollectionString(app.$translate('month_short'), app.$translate('year_short'));


            return app.$translate('debt_collection_duration_tooltip', 'Om du väljer att samla dina lån är löptiden istället {duration}')
                .replace('{duration}', duration);
        }
        return null;
    });

    inject('getDurationString', (model, alternateDataType, alternateDataDisplay) => {
        const alternateDataKeys = getAlternateDataKeys({dataType: alternateDataType, dataDisplay: alternateDataDisplay});

        if (app.$isSpain()) {
            return app[alternateDataKeys.getSpanishLoanDuration](model, app.$translate('month_short'), app.$translate('year_short'));
        }
        return model[alternateDataKeys.getDurationString](app.$translate('month_short'), app.$translate('year_short'));
    });
};

const formatSpanishDurationDays = (minDuration, maxDuration) => {

    // minDuration = minDuration === 1 ? 2 : minDuration;
    if (minDuration === maxDuration) {
        return `${minDuration * 30 + 1} días`;
    }

    // if (maxDuration <= 2) {
    //     return `${minDuration * 30 + 1}+ días`;
    // }

    return `${minDuration * 30 + 1} - ${maxDuration * 30} días`;
}

// TODO: Move functionality to core-models
const getSpanishLoanDuration = (model, monthShort, yearShort) => {
    
    // TODO: leaving this for potential Google Ads in the future

    let minDuration = model.minDuration;
    let maxDuration = model.maxDuration;

    if (maxDuration <= 6) {
        return formatSpanishDurationDays(minDuration, maxDuration);
    }

    // if (minDuration < 3) {
    //     // eslint-disable-next-line camelcase
    //     model._data.duration_interval_min = 3;
    // }

    return model.getDurationString(monthShort, yearShort);
};

const getSpanishDebtCollectionDuration = (model, monthShort, yearShort) => {
    const minDuration = model.minDurationDebtCollection;
    const maxDuration = model.maxDurationDebtCollection;

    if (maxDuration <= 6) {
        return formatSpanishDurationDays(minDuration, maxDuration);
    }

    return model.getDurationDebtCollectionString(monthShort, yearShort);
};

const isNorway = () => {
    return process.env.PRISMIC_LOCALE === 'no';
};

const isSweden = () => {
    return process.env.PRISMIC_LOCALE === 'sv-se';
};

const isSpain = () => {
    return process.env.PRISMIC_LOCALE === 'es-es';
};

const isEstonia = () => {
    return process.env.PRISMIC_LOCALE === 'et-ee';
};

const isDenmark = () => {
    return process.env.PRISMIC_LOCALE === 'da-dk';
};

const removeParagraphs = (html) => {
    return html.replace(/<p>|<\/p>/g, '');
    // return html;
};

const formatId = (id) => {
    return id.replace('$', '-');
};

const getCreditCheckString = (creditCheck) => {
    return {
        'creditsafe': 'Creditsafe',
        'bisnode': 'Bisnode',
        'safenode': 'Safenode',
        'uc': 'UC'
    }[creditCheck] || 'unknown';
};

const getDirectPaymentToBanksString = (model) => {
    const banks = {
        'hasDirectPaymentToDanskeBank': 'DanskeBank',
        'hasDirectPaymentToHandelsbanken': 'Handelsbanken',
        'hasDirectPaymentToNordea': 'Nordea',
        'hasDirectPaymentToSEB': 'SEB',
        'hasDirectPaymentToSwedbank': 'Swedbank',
    };
    return Object.entries(banks)
        .filter(([key]) => model[key])
        .map(([,value]) => value)
        .join(', ');
};

const getOrderedList = (list, allLenders, modelClass) => {

    let arr = []; 
    let maxSalesCount = 0;
    let maxConversionRate = 0;
    let maxEpc = 0;

    list.forEach(l => {
        const lender = Object.assign({}, allLenders[l.id]);
        lender.model = new modelClass(lender);

        maxEpc = lender.model._core.sales_stats.epc > maxEpc ? lender.model._core.sales_stats.epc : maxEpc;
        maxSalesCount = lender.model._core.sales_stats.sales > maxSalesCount ? lender.model._core.sales_stats.sales : maxSalesCount;
        maxConversionRate = lender.model._core.sales_stats.conversion_rate > maxConversionRate ? lender.model._core.sales_stats.conversion_rate : maxConversionRate;

        arr.push(lender);
    });

    arr.forEach(lender => {

        if (lender.model._core.sales_stats.sales === 0) {
            lender.order = lender.model._core.sales_stats.clicks * -1;
        }
        else if (lender.model._core.sales_stats.sales < 5) {
            lender.order =
                lender.model._core.sales_stats.epc / maxEpc +
                lender.model._core.sales_stats.sales / maxSalesCount +
                lender.model._core.sales_stats.conversion_rate / maxConversionRate;
        }
        else {
            lender.order =
                (8 * (lender.model._core.sales_stats.epc / maxEpc)) +
                lender.model._core.sales_stats.sales / maxSalesCount +
                lender.model._core.sales_stats.conversion_rate / maxConversionRate;
        }
    });

    return arr.slice(0).sort((a, b) => b.order - a.order);
};

function getAlternateDataKeys({dataType, dataDisplay} = {}) {
    // getSpanishLoanDuration is injected function. Not model method
    if (dataType === 'debt_collection' && dataDisplay === 'override') {
        return {
            minAmount: 'minAmountDebtCollection',
            maxAmount: 'maxAmountDebtCollection',
            minAmountString: 'minAmountDebtCollectionString',
            maxAmountString: 'maxAmountDebtCollectionString',
            amountString: 'amountDebtCollectionString',
            minDuration: 'minDurationDebtCollection',
            maxDuration: 'maxDurationDebtCollection',
            getDurationString: 'getDurationDebtCollectionString',
            getMinDurationString: 'getMinDurationDebtCollectionString',
            getMaxDurationString: 'getMaxDurationDebtCollectionString',
            getSpanishLoanDuration: '$getSpanishDebtCollectionDuration',
        };
    }
    return {
        minAmount: 'minAmount',
        maxAmount: 'maxAmount',
        minAmountString: 'minAmountString',
        maxAmountString: 'maxAmountString',
        amountString: 'amountString',
        minDuration: 'minDuration',
        maxDuration: 'maxDuration',
        getDurationString: 'getDurationString',
        getMinDurationString: 'getMinDurationString',
        getMaxDurationString: 'getMaxDurationString',
        getSpanishLoanDuration: '$getSpanishLoanDuration',
    };
};

const getAlternateDataConfig = (slice) => {
    return {
        dataType: slice.primary.alternate_data,
        dataDisplay: slice.primary.alternate_data_display,
    };
}