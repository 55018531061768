import { size, keyBy } from 'lodash';
import { replace as replaceHelper, queryAll } from '@swegaming-ab/nuxtjs-helpers';

export const state = () => ({
    lenders: {},
    latestLenderUserRatings: [],
    corporateLenders: {},
    cards: {},
    customServices: {},
    navigation: {},
    footer: {},
    insertLists: {},
    lists: {},
    translations: {},
    authors: {},
    settings: {}
});

export const mutations = {
    SET_STATE(state, { key, value }) {
        state[key] = replaceHelper(value, state.currentPrismicLocale);
    }
};

export const actions = {
    async fetchStates({ commit }, {$prismic}) {
        try {

            if (size(this.state.navigation) === 0) {
                const navigation = await $prismic.api.getSingle('navigation', {
                    lang: process.env.PRISMIC_LOCALE 
                });

                if (navigation) {
                    commit('SET_STATE', {
                        key: 'navigation',
                        value: navigation.data
                    });
                }
            }

            if (size(this.state.translations) === 0) {
                const translations = await $prismic.api.getSingle('translations', { 
                    lang: process.env.PRISMIC_LOCALE 
                });

                Object.keys(translations.data).forEach(key => {
                    if (! translations.data[key]) {
                        delete translations.data[key];
                    }
                });

                if (translations) {
                    commit('SET_STATE', {
                        key: 'translations',
                        value: translations.data
                    });
                }
            }

            if (size(this.state.footer) === 0) {
                const footer = await $prismic.api.getSingle('footer', { 
                    lang: process.env.PRISMIC_LOCALE 
                });

                footer.data.column_1_items.forEach(iterator => {
                    cleanUpLinkData(iterator.link);
                });
                footer.data.column_2_items.forEach(iterator => {
                    cleanUpLinkData(iterator.link);
                });
                footer.data.column_3_items.forEach(iterator => {
                    cleanUpLinkData(iterator.link);
                });

                footer.data.body.forEach(slice => {
                    if (slice.slice_type !== 'link_column') {
                        return;
                    }
                    slice.items.forEach(iterator => {
                        cleanUpLinkData(iterator.link_item);
                    });
                });
                footer.data.credit_card_columns.forEach(slice => {
                    if (slice.slice_type !== 'link_column') {
                        return;
                    }
                    slice.items.forEach(iterator => {
                        cleanUpLinkData(iterator.link_item);
                    });
                });

                if (footer) {
                    commit('SET_STATE', {
                        key: 'footer',
                        value: footer.data
                    });
                }
            }

            if (size(this.state.lists) === 0) {
                const lists = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'list'),
                    { lang: process.env.PRISMIC_LOCALE }
                );

                lists.forEach(list => {
                    cleanUpDocumentData(list);

                    list.data.items.forEach(iterator => {
                        cleanUpLinkData(iterator.item);
                    });
                });

                commit('SET_STATE', {
                    key: 'lists',
                    value: keyBy(lists, 'id')
                });
            }

            if (size(this.state.insertLists) === 0) {
                const insertLists = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'insert_list'),
                    { lang: process.env.PRISMIC_LOCALE }
                );

                insertLists.forEach(list => {
                    cleanUpDocumentData(list);

                    list.data.lenders_to_insert.forEach(iterator => {
                        cleanUpLinkData(iterator.lender);
                    });
                });
                
                commit('SET_STATE', {
                    key: 'insertLists',
                    value: keyBy(insertLists, 'id')
                });
            }

            if (size(this.state.lenders) === 0) {
                const lenders = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'lender'),
                    { orderings: '[document.first_publication_date desc]', lang: process.env.PRISMIC_LOCALE }
                );

                lenders.forEach(lender => {
                    cleanUpDocumentData(lender);

                    delete lender.data.seo_title;
                    delete lender.data.seo_description;
                    delete lender.data.content;
                    delete lender.data.pros;
                    delete lender.data.cons;
                    delete lender.data.requirements;
                    delete lender.data.faq;
                    delete lender.data.comparision_type;
                    delete lender.data.comparision;

                    delete lender.data.core_api.core_data.source_sales_stats;
                });

                commit('SET_STATE', {
                    key: 'lenders',
                    value: keyBy(lenders, 'id')
                });
            }

            if (size(this.state.corporateLenders) === 0) {
                const lenders = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'lender_corporate'),
                    { orderings: '[document.first_publication_date desc]', lang: process.env.PRISMIC_LOCALE }
                );

                lenders.forEach(lender => {
                    cleanUpDocumentData(lender);

                    delete lender.data.seo_title;
                    delete lender.data.seo_description;
                    delete lender.data.content;
                    delete lender.data.pros;
                    delete lender.data.cons;
                    delete lender.data.requirements;
                    delete lender.data.faq;
                    delete lender.data.preamble;

                    delete lender.data.core_api.core_data.source_sales_stats;
                });

                commit('SET_STATE', {
                    key: 'corporateLenders',
                    value: keyBy(lenders, 'id')
                });
            }

            if (size(this.state.cards) === 0) {
                const cards = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'credit_card'),
                    { orderings: '[document.first_publication_date desc]', lang: process.env.PRISMIC_LOCALE }
                );

                cards.forEach(card => {
                    cleanUpDocumentData(card);

                    delete card.data.seo_title;
                    delete card.data.seo_description;
                    delete card.data.content;
                    delete card.data.preamble;

                    delete card.data.core_api.core_data.source_sales_stats;
                });

                commit('SET_STATE', {
                    key: 'cards',
                    value: keyBy(cards, 'id')
                });
            }

            if (size(this.state.customServices) === 0) {
                const customServices = await queryAll(
                    $prismic.api,
                    $prismic.predicates.at('document.type', 'custom_service'),
                    { orderings: '[document.first_publication_date desc]', lang: process.env.PRISMIC_LOCALE }
                );

                customServices.forEach(service => {
                    cleanUpDocumentData(service);
                });

                commit('SET_STATE', {
                    key: 'customServices',
                    value: keyBy(customServices, 'id')
                });
            }

            if (size(this.state.authors) === 0) {
                const authors = await queryAll(
                    $prismic.api, 
                    $prismic.predicates.at('document.type', 'author'),
                    { lang: process.env.PRISMIC_LOCALE }
                );

                authors.forEach(author => {
                    cleanUpDocumentData(author);

                    delete author.data.seo_title;
                    delete author.data.seo_description;
                    delete author.data.bio_long;
                    delete author.data.quote;
                    delete author.data.experiences;
                });

                commit('SET_STATE', {
                    key: 'authors',
                    value: keyBy(authors, 'id')
                });
            }

            if (size(this.state.settings) === 0) {
                const settings = await $prismic.api.getSingle('settings', { 
                    lang: process.env.PRISMIC_LOCALE 
                });
                if (settings) {
                    commit('SET_STATE', {
                        key: 'settings',
                        value: settings.data
                    });
                }
            }

            if (size(this.state.latestLenderUserRatings) === 0) {
                let ratings = [];

                Object.values(this.state.lenders).forEach(lender => {
                    if (lender.data.core_api && lender.data.core_api.ratings) {
                        lender.data.core_api.ratings.data.forEach(rating => {
                            ratings.push(Object.assign({
                                lenderId: lender.id
                            }, rating));
                        });
                    }
                });

                ratings.sort((r1, r2) => r2.id - r1.id);
                ratings = ratings.slice(0, 5);

                commit('SET_STATE', {
                    key: 'latestLenderUserRatings',
                    value: ratings
                });
            }

            /*
            

            if(size(this.state.translations) == 0) {
                const translations = await $prismic.api.getSingle('translations', {
                    lang: process.env.PRISMIC_LANG
                });
                if(translations) {
                    commit('SET_STATE', {
                        key: 'translations',
                        value: translations.data
                    });
                }
            }
            
            ....

            */


        } 
        catch (e) {
            console.log(e);
        }
    }
};

const cleanUpDocumentData = (document) => {
    delete document.url;
    delete document.href;
    delete document.slugs;
    delete document.linked_documents;
    delete document.alternate_languages;
    delete document.first_publication_date;
    delete document.last_publication_date;
};

const cleanUpLinkData = (link) => {
    delete link.tags;
    delete link.lang;
    delete link.slug;
    delete link.first_publication_date;
    delete link.last_publication_date;
    delete link.isBroken;
};